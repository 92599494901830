function horizontalImageSlider(id, e) {
	if (e.type === "DOMContentLoaded" || e.type === "resize") {
		localStorage.setItem("slideIndex_" + id, 0);
		scrollSpeed = 1200;
		imageContainerId = "#" + id;
		imageGalleryScrollContainer = $(imageContainerId).find('.horizontal-scroll');
		imageGalleryScrollContainer[0].scrollLeft = 0;
		tempitemfirstChild = $(imageContainerId).find('.horizontal-scroll .item:first-child');
		itemDiv = $(imageGalleryScrollContainer).find('.item')[0];
		horizontalScrollDivWidth = imageGalleryScrollContainer[0].offsetWidth;
		itemWidthValue = parseInt(window.getComputedStyle(itemDiv).width) + parseInt(window.getComputedStyle(itemDiv).marginLeft) + parseInt(window.getComputedStyle(itemDiv).marginRight);
		itemsperScroll = parseInt(horizontalScrollDivWidth / itemWidthValue);
		var spaceBetweenItems = 5.5;
		scrollWidth = ((itemWidthValue + spaceBetweenItems) * itemsperScroll);//window width per slide
		var tempId = imageGalleryScrollContainer;
		$(tempId).css('max-width', scrollWidth);
		localStorage.setItem("scrollWidth_" + id, scrollWidth);
		noOfItems = $(imageGalleryScrollContainer[0]).find('.item').length;
		slideNumber = parseInt(noOfItems / itemsperScroll);//number of slide on click of arrow button
		localStorage.setItem("slideNumber_" + id, slideNumber);
		previousArrow(id, localStorage.getItem("slideIndex_" + id));
		nextArrow(id, localStorage.getItem("slideIndex_" + id));
	}
	if (e.type === "click") {
		if ($(e.currentTarget).hasClass('horizontal-arrow-right')) {
			rightScroll(id);
		}
		if ($(e.currentTarget).hasClass('horizontal-arrow-left')) {
			leftScroll(id);
		}
	}

	function rightScroll(scrollContainerId) {
		scrollContainerIdDiv = "#" + scrollContainerId;
		var scrollContainerSlideIndex = parseInt(localStorage.getItem("slideIndex_" + scrollContainerId));
		var scrollContainerSlideNumber = parseInt(localStorage.getItem("slideNumber_" + scrollContainerId));
		var scrollContainerWidth = parseInt(localStorage.getItem("scrollWidth_" + scrollContainerId));
		if (scrollContainerSlideIndex < scrollContainerSlideNumber) {
			scrollContainerSlideIndex++;
			$(scrollContainerIdDiv).find('.horizontal-scroll').animate({
				scrollLeft: '+=' + scrollContainerWidth
			}, scrollSpeed);
		}
		nextArrow(scrollContainerId, scrollContainerSlideIndex);
		previousArrow(scrollContainerId, scrollContainerSlideIndex);
		localStorage.setItem("slideIndex_" + scrollContainerId, scrollContainerSlideIndex);
	}
	function leftScroll(scrollContainerId) {
		scrollContainerIdDiv = "#" + scrollContainerId;
		var scrollContainerSlideIndex = localStorage.getItem("slideIndex_" + scrollContainerId);
		var scrollContainerWidth = localStorage.getItem("scrollWidth_" + scrollContainerId);
		if (scrollContainerSlideIndex > 0) {
			scrollContainerSlideIndex--;
			$(scrollContainerIdDiv).find('.horizontal-scroll').animate({
				scrollLeft: '-=' + scrollContainerWidth
			}, scrollSpeed);
		}
		localStorage.setItem("slideIndex_" + scrollContainerId, scrollContainerSlideIndex);
		nextArrow(scrollContainerId, scrollContainerSlideIndex);
		previousArrow(scrollContainerId, scrollContainerSlideIndex);
	}
	function nextArrow(scrollContainerId, slideIndex) {
		var scrollContainerSlideNumber = localStorage.getItem("slideNumber_" + scrollContainerId);
		var scrollContainerIdDiv = "#" + scrollContainerId;
		if (slideIndex >= scrollContainerSlideNumber) {
			$(scrollContainerIdDiv).find('.horizontal-arrow-right').css('opacity', '0.3');
		}
		else {
			$(scrollContainerIdDiv).find('.horizontal-arrow-right').css('opacity', '1');
		}
	}
	function previousArrow(scrollContainerId, slideIndex) {
		var scrollContainerIdDiv = "#" + scrollContainerId;
		if (slideIndex <= 0) {
			$(scrollContainerIdDiv).find('.horizontal-arrow-left').css('opacity', '0.3');
		}
		else {
			$(scrollContainerIdDiv).find('.horizontal-arrow-left').css('opacity', '1');
		}
	}
}